<template>
  <v-list-item class="px-0">
    <v-list-item-avatar v-if="icon">
      <v-icon v-text="icon" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="title" />
    </v-list-item-content>
    <v-list-item-action>
      <v-switch
        hide-details
        v-bind="$attrs"
        :input-value="value"
        @change="$emit('input', $event)"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'FormSwitcher',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
